<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-row :gutter='24'>
      <a-col :sm='12' :md='12' :xl='4'>
        <a-card >

           <a-descriptions :column="1">
             <a-descriptions-item label="实时现金折扣"><span class="card_title">￥{{dashboard.memberCash+dashboard.preCash+dashboard.merchantCash}}</span></a-descriptions-item>
             <a-descriptions-item label="用户" ><span class="card_title">￥{{dashboard.memberCash}}</span></a-descriptions-item>
             <a-descriptions-item label="备选" ><span class="card_title">￥{{dashboard.preCash}}</span></a-descriptions-item>
             <a-descriptions-item label="商户" ><span class="card_title">￥{{dashboard.merchantCash}}</span></a-descriptions-item>
           </a-descriptions>
        </a-card>
      </a-col>

      <a-col :sm='12' :md='12' :xl='4'>
        <a-card >

          <a-descriptions :column="1">
            <a-descriptions-item label="获得积分合计"><span class="card_title">{{dashboard.getCash}}</span></a-descriptions-item>
            <a-descriptions-item label="使用积分合计" ><span class="card_title">{{ dashboard.useCash }}</span></a-descriptions-item>
            <a-descriptions-item label="剩余积分合计" ><span class="card_title">{{ dashboard.remainCash }}</span></a-descriptions-item>
            <a-descriptions-item  ></a-descriptions-item>
          </a-descriptions>
        </a-card>
      </a-col>

      <a-col :sm='24' :md='24' :xl='6' :style="{ marginBottom: '24px' }">

        <a-card >

          <a-descriptions :column="2">
            <a-descriptions-item label="混用得"><span class="card_title">{{dashboard.orderMixGetCash}}</span></a-descriptions-item>
            <a-descriptions-item label="用户得" ><span class="card_title">{{dashboard.memberGetCash}}</span></a-descriptions-item>

            <a-descriptions-item label="纯用得" ><span class="card_title">{{dashboard.orderOnlyGetCash}}</span></a-descriptions-item>
            <a-descriptions-item label="备选得" ><span class="card_title">{{dashboard.preGetCash}}</span></a-descriptions-item>

            <a-descriptions-item label="现金得">{{dashboard.orderWeixinGetCash}}</a-descriptions-item>
            <a-descriptions-item label="商户得" ><span class="card_title">{{dashboard.merchantGetCash}}</span></a-descriptions-item>

            <a-descriptions-item ></a-descriptions-item>
            <a-descriptions-item ></a-descriptions-item>
          </a-descriptions>
        </a-card>
      </a-col>

      <a-col :sm='24' :md='24' :xl='6' :style="{ marginBottom: '24px' }">
        <a-card >
          <a-descriptions :column="2">
            <a-descriptions-item label="混用"><span class="card_title">{{dashboard.orderMixUseCash}}</span></a-descriptions-item>
            <a-descriptions-item label="用户用" ><span class="card_title">{{dashboard.memberUsedCash}}</span></a-descriptions-item>

            <a-descriptions-item label="纯用" ><span class="card_title">{{dashboard.orderOnlyUseCash}}</span></a-descriptions-item>
            <a-descriptions-item label="备选用" ><span class="card_title">{{dashboard.preUsedCash}}</span></a-descriptions-item>

            <a-descriptions-item ></a-descriptions-item>
            <a-descriptions-item label="商户用" ><span class="card_title">{{dashboard.merchantUsedCash}}</span></a-descriptions-item>

            <a-descriptions-item ></a-descriptions-item>
            <a-descriptions-item ></a-descriptions-item>
          </a-descriptions>
        </a-card>
      </a-col>


      <a-col :sm='24' :md='24' :xl='4' :style="{ marginBottom: '24px'}" >

        <a-card>
          <a-descriptions :column="1">
            <a-descriptions-item label="修改获得"><span class="card_title">{{ dashboard.updateGetCash }}</span></a-descriptions-item>
            <a-descriptions-item label="修改减少" ><span class="card_title">{{dashboard.updateUseCash}}</span></a-descriptions-item>

            <a-descriptions-item label="管理奖">{{ dashboard.manageGetCah }}</a-descriptions-item>
            <a-descriptions-item label="日奖励">{{ dashboard.dailyGetCash }}</a-descriptions-item>
          </a-descriptions>
        </a-card>
      </a-col>

    </a-row>
    <a-form-model ref='form' :model='form' layout='inline'>
      <a-form-model-item prop='recordType'>
        <a-select v-model="form.recordType" placeholder="分类" style="width: 200px">
          <a-select-option :value="1">
            获得
          </a-select-option>
          <a-select-option :value="2">
            使用
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item prop='hType' v-if="this.form.recordType===1">
        <a-select  v-model="form.type" placeholder="变动类型" style="width: 200px">
          <a-select-option :value="undefined">
            变动类型
          </a-select-option>
          <a-select-option :value="2">
            修改
          </a-select-option>
          <a-select-option :value="6">
            受让
          </a-select-option>
          <a-select-option :value="9">
            积分补贴
          </a-select-option>
          <a-select-option :value="15">
            推广
          </a-select-option>
<!--          <a-select-option :value="16">-->
<!--            推广+活动-->
<!--          </a-select-option>-->
          <a-select-option :value="19">
            消费积分
          </a-select-option>
          <a-select-option :value="28">
            业绩15%
          </a-select-option>
          <a-select-option :value="26">
            业绩5%
          </a-select-option>
          <a-select-option :value="31">
            业绩3%
          </a-select-option>
          <a-select-option :value="17">
            业绩2.5%
          </a-select-option>
          <a-select-option :value="24">
            业绩2%
          </a-select-option>
        <!--  <a-select-option :value="25">
            业绩1-1%
          </a-select-option>-->
          <a-select-option :value="27">
            业绩1%
          </a-select-option>
         <!-- <a-select-option :value="28">
            业绩10%
          </a-select-option>-->
          <a-select-option :value="22">
            批兑驳回
          </a-select-option>
          <a-select-option :value="29">
            拓展服务
          </a-select-option>
          <a-select-option :value="30">
            体验积分
          </a-select-option>
        </a-select>

      </a-form-model-item>

      <a-form-model-item prop='sType' v-if="this.form.recordType===2">
        <a-select v-model="form.type" placeholder="变动类型" style="width: 200px">
          <a-select-option :value="undefined">
            变动类型
          </a-select-option>
<!--          <a-select-option :value="1">-->
<!--            使用-->
<!--          </a-select-option>-->
<!--          <a-select-option :value="2">-->
<!--            修改-->
<!--          </a-select-option>-->
<!--          <a-select-option :value="5">-->
<!--            转让-->
<!--          </a-select-option>-->
<!--          <a-select-option :value="10">-->
<!--            门票-->
<!--          </a-select-option>-->
<!--          <a-select-option :value="13">-->
<!--            商户删除-->
<!--          </a-select-option>-->
          <a-select-option :value="1">
            使用
          </a-select-option>
          <a-select-option :value="2">
            修改
          </a-select-option>
          <a-select-option :value="5">
            转赠
          </a-select-option>
          <a-select-option :value="13">
            商户删除
          </a-select-option>
          <a-select-option :value="21">
            批兑商品
          </a-select-option>
          <a-select-option :value="23">
            解约
          </a-select-option>
        </a-select>
      </a-form-model-item>

<!--      <a-form-model-item prop='memberType'>-->
<!--        <a-select v-model="form.memberType" placeholder="折扣类型" style="width: 200px">-->
<!--          <a-select-option :value="undefined">-->
<!--            折扣类型-->
<!--          </a-select-option>-->
<!--          <a-select-option :value="3">-->
<!--            65%-->
<!--          </a-select-option>-->
<!--          <a-select-option :value="2">-->
<!--            55%-->
<!--          </a-select-option>-->
<!--          <a-select-option :value="1">-->
<!--            45%-->
<!--          </a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->

      <a-form-model-item prop='endTag'>
        <a-select v-model="form.endTag" placeholder="订单尾号" style="width: 200px">
          <a-select-option value="-1">
            全部
          </a-select-option>
<!--          <a-select-option v-for="i in 8" :key="i" :value="i">-->
<!--            {{i}}-->
<!--          </a-select-option>-->
          <a-select-option value="Y">
            Y
          </a-select-option>
          <a-select-option value="BF">
            BF
          </a-select-option>
          <a-select-option value="BY">
            BY
          </a-select-option>
          <a-select-option value="S">
            S
          </a-select-option>
          <a-select-option value="H">
            H
          </a-select-option>
          <a-select-option value="JFZT">
            JFZT
          </a-select-option>
          <a-select-option value="SQZT">
            SQZT
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item prop='keyword'>
        <a-input v-model='form.keyword' style="width: 200px" placeholder="姓名/手机" />
      </a-form-model-item>

      <a-form-model-item prop='start'>
        <a-range-picker
          v-model="dateArr"
          :format="[formatType, formatType]"
          :show-time="{
            hideDisabledOptions: true
          }"
          :disabled-date="disabledDate"
          @change="dateChange"
        />
      </a-form-model-item>
      <a-form-model-item>
        <a-button type='primary' @click='handleSubmit'>
          查询
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click='handleReset'>
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <div class='table-operator' style="margin-top: 20px">
        <a-list :loading="topLoading">
          <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)' style="margin-right: 30px;">刷新</a-button>
          <span style="margin-right: 20px;">笔数：{{this.total}}</span>
          <span style="margin-right: 20px;">变动值：{{this.ext4}}</span>
        </a-list>

      </div>
      <s-table id="table" ref='table' rowKey='id' size='default' :columns='columns' :scroll='{ x: 1500}' :data='loadData'>
        <span slot='memberType' slot-scope='text'>
          <template>
            {{ text == "store" ? "65%" : text == "center" ? "55%" : text == "president" ? "45%" :  "" }}
          </template>
        </span>
        <span slot='businessContent' slot-scope='text, record'>
          <template>
            {{ (record.afterBalance - record.beforeBalance) | parseMoney }}
          </template>
        </span>
        <span slot='orderId' slot-scope='text, record'>
          <template>
<!--            {{record.recordType}}-->
             {{showText(record)}}
          </template>
        </span>
      </s-table>
    </div>

  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis, ChartCard } from '@/components'
import {
  getAuditOrderData,
  queryMemberOrders,
  removeAuditOrder,
  putOrderExtra,
  getMobileMember,
  getMerchantList,
  queryCash, queryCashHead
} from '@/api/member-order'
import moment from 'moment'


const columns = [
  {
    width: 80,
    title: '用户类型',
    dataIndex: 'abc',
    scopedSlots: { customRender: 'abc' }
  },
  {
    width: 100,
    title: '变动类型',
    dataIndex: 'businessName',
    scopedSlots: { customRender: 'businessName' }
  },
  {
    width: 80,
    title: '变动值',
    dataIndex: 'businessContent',
    scopedSlots: { customRender: 'businessContent' }
  },
  {
    width: 80,
    title: '变动后数值',
    dataIndex: 'afterBalance',
    scopedSlots: { customRender: 'afterBalance' }
  },
  {
    width: 100,
    title: '发生订单号',
    dataIndex: 'orderId',
    scopedSlots: { customRender: 'orderId' }
  },
  {
    width: 130,
    title: '时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    width: 100,
    title: '备注',
    dataIndex: 'des',
    scopedSlots: { customRender: 'des' }
  },
    {
    width: 80,
    title: '姓名',
    dataIndex: 'userName',
    scopedSlots: { customRender: 'userName' }
  },
  {
    width: 100,
    title: '手机号',
    dataIndex: 'mobile',
    scopedSlots: { customRender: 'mobile' }
  },
]

const formatType = 'YYYY-MM-DD';
const baseStart = moment().startOf('month').format(formatType);
const baseEnd = moment().format(formatType);

export default {
  name: 'AuditOrderList',
  components: {
    STable, Ellipsis, ChartCard
  },
  data() {
    return {
      // 筛选表单
      topLoading:true,
      showType:0,
      formatType,
      dateArr: [baseStart, baseEnd],
      form: {
        recordType:1,
        keyword: "",
        auditStatus: 1,
        start: moment(baseStart).startOf('day').format(formatType + " HH:mm"),
        end: moment(baseEnd).endOf('day').format(formatType+ " HH:mm"),
      },
      loading: true,
      dashboard: {}, //订单统计数据
      // 表头
      columns,
      // 加载数据方法 必须为 Promise 对象
      queryParam: {},
      total:0,
      ext4:0,
      loadData: parameter => {
        parameter.pageNum = parameter.current;
        parameter.pageSize = parameter.size;
        delete parameter.current;
        delete parameter.size;
        let param = Object.assign(parameter, this.queryParam, this.form);
        //商品折扣：100、65、55、45 现金折扣：65、55、45
        if(param.memberType=='1') {
          param.memberType = 'president'
        }else if(param.memberType=='2'){
            param.memberType = 'center'
        }else if(param.memberType=='3'){
          param.memberType = 'store'
        }

        return queryCash(param)
          .then(datum => {
            this.total = datum.total;
            this.ext4 = datum.ext4;
            this.ext = {
              ext1: datum.ext1 !== undefined ? datum.ext1 : '',
              ext2: datum.ext2,
              ext3: datum.ext3,
            }
            return datum
          })
      },
      merchantList: [],
    }
  },
  created() {
    this.getOrderData();
  },
  methods: {
    showText(record){
      // debugger;
      let text = "";
      if(record.recordType===2|| record.recordType === 9||record.recordType===13||record.recordType===4){
        text = record.opUsername;
      }else if(record.recordType === 6){
        text = record.transferFromUserName+"转";
      }else if(record.recordType === 5){
        text = record.transferToUserName||'未知';
        text+="得"
      }else{
        text = record.orderId;
      }

      return text;
    },
    moment,
    onChangeType() {
      this.$set(this.deliver1.form, "extraRewardMobile", "");
      this.$set(this.deliver1.form, "extraRewardName", "");
      this.$set(this.deliver1.form, "extraRewardMerchantName", "");
      this.$set(this.deliver1.form, "extraRewardAuthCode", "");
      this.merchantList = [];
    },
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    dateChange() {
      this.form.start =  moment(this.dateArr[0]).startOf('day').format(this.formatType + " HH:mm");
      this.form.end =  moment(this.dateArr[1]).endOf('day').format(this.formatType+ " HH:mm");
    },
    handleReset() {
      this.dateArr = [baseStart, baseEnd];
      this.form.start = moment(baseStart).startOf('day').format(this.formatType + " HH:mm");
      this.form.end = moment(baseEnd).endOf('day').format(this.formatType + " HH:mm");
      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
    },
    handleSubmit() {
      this.getOrderData();
      this.$refs.table.refresh(true)
    },
    getOrderData() {
      //顶部统计数据
      if(this.form.endTag === "-1"){
        //尾号搜索过滤全部，去掉条件等于搜索全部
        delete this.form.endTag;
      }
      this.topLoading = true;

      let param = Object.assign(Object.assign({}, this.form));
      //商品折扣：100、65、55、45 现金折扣：65、55、45
      if(param.memberType=='1') {
        param.memberType = 'president'
      }else if(param.memberType=='2'){
        param.memberType = 'center'
      }else if(param.memberType=='3'){
        param.memberType = 'store'
      }

      queryCashHead(param).then(result => {
        //数据处理
        // if(param.type=='7'){
        //   //如果是取删退回查询，现金获得为0
        //   result.orderGetCash = 0;
        // }

        result.storeCash = result.storeCash || 0;
        result.centerCash = result.centerCash || 0;
        result.presidentCash = result.presidentCash || 0;
        result.timeCashTotal = result.storeCash + result.centerCash + result.presidentCash;

        result.queryStoreCash = result.queryStoreCash || 0;
        result.queryCenterCash = result.queryCenterCash || 0;
        result.queryPresidentCash = result.queryPresidentCash || 0;
        result.searchCashTotal = result.queryStoreCash + result.queryCenterCash + result.queryPresidentCash;

        result.orderGetCash = result.orderGetCash || 0;
        result.updateGetCash = result.updateGetCash || 0;
        result.subsidyGetCash = result.subsidyGetCash || 0;
        result.transferGetCash = result.transferGetCash || 0;
        result.centerGetCash = result.centerGetCash || 0;
        result.presidentGetCash = result.presidentGetCash || 0;
        result.returnGetCash = result.returnGetCash || 0;
        result.getTotal = result.orderGetCash + result.updateGetCash + result.subsidyGetCash ;

        result.orderUseCash = result.orderUseCash || 0;
        result.updateUseCash = result.updateUseCash || 0;
        result.ticketUseCash = result.ticketUseCash || 0;
        result.orderOnlyUseCash = result.orderOnlyUseCash || 0;
        result.orderMixUseCash = result.orderMixUseCash || 0;
        result.transferToCash = result.transferToCash || 0;
        result.deleteMerchant = result.deleteMerchant || 0;
        result.useTotal = result.orderUseCash + result.updateUseCash + result.ticketUseCash ;

        // debugger;

        this.dashboard = result;
        this.loading = false;
        this.topLoading=false;
      })
    },
    getAuthCodeList(authCode) {
        if (authCode) {
          let index = authCode.indexOf("-");
          if (index < 0) {
            index = authCode.length - 1;
          } else {
            index--;
          }
          let ownAuthCode = authCode.slice(index - 3, index + 1);
          if (ownAuthCode) {
            const authCodeList = authCode.split(ownAuthCode);
            authCodeList.splice(1, 0, ownAuthCode);
            return authCodeList;
          }
        }
        return [];
    },
    getMonthFinalDay(year,month){
      let day='';
      if(year == null || year===''){
        year = new Date().getFullYear();
      }
      if(month == null || month===''){
        month = new Date().getMonth()+1;
      }
      day = new Date(new Date(year,month).setDate(0)).getDate();
      return year+"-"+month+"-"+day;

    },
    getDataByMonth(tabs){
      let start = "";
      let end = "";
      if(tabs === "1"){
        //本月
        let year = new Date().getFullYear();
        let month = new Date().getMonth()+1;
        end = this.getMonthFinalDay(year,month);
        start = year + "-" + month + "-" + "01";
      }else{
        //上月
        let lastMonth = this.getLastMonth();
        let monthYear = lastMonth.split("-");
        let year = monthYear[0];
        let month = monthYear[1];
        end = this.getMonthFinalDay(year,month);
        start = year + "-" + month + "-" + "01";
      }
      end = end + " 23:59";
      start = start + " 00:00";
      let arr={};
      arr.start=start;
      arr.end =end;
      return arr;
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.order_count .ant-card-body {
  padding: 16px 24px 10px !important;
  display: flex;
  flex-wrap: wrap;
}
.card_title {
    width: 100%;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 22px;
    display: flex;
}
.card_content {
  width: 100%;
  font-size: 18px;
  line-height: 30px;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  margin-top: 4px;
  color: #000;
  .card_content_title {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
  }
}
.order_count .card_content {
  width: 50%;
}
</style>
